var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$can("read", "users")
    ? _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c("b-card-header", { staticClass: "pb-50" }, [
            _c("h5", [_vm._v(" Permissions ")])
          ]),
          _c("b-table", {
            staticClass: "mb-0",
            attrs: { striped: "", responsive: "", items: _vm.permissionsData },
            scopedSlots: _vm._u(
              [
                {
                  key: "cell(module)",
                  fn: function(data) {
                    return [_vm._v(" " + _vm._s(data.value) + " ")]
                  }
                },
                {
                  key: "cell()",
                  fn: function(data) {
                    return [
                      _c("b-form-checkbox", {
                        attrs: { disabled: "", checked: data.value }
                      })
                    ]
                  }
                }
              ],
              null,
              false,
              3253338529
            )
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }