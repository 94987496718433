var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.$can("read", "users")
        ? _c(
            "b-card",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "d-flex justify-content-between flex-column",
                      attrs: { cols: "21", xl: "4", lg: "4" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-start" },
                        [
                          _c("b-avatar", {
                            attrs: {
                              src: _vm.userData.avatar,
                              text: _vm.avatarText(_vm.userData.fullName),
                              variant:
                                "light-" +
                                _vm.resolveUserRoleVariant(_vm.userData.role),
                              size: "104px",
                              rounded: ""
                            }
                          }),
                          _c(
                            "div",
                            { staticClass: "d-flex flex-column ml-1" },
                            [
                              _c("div", { staticClass: "mb-1" }, [
                                _c("h4", { staticClass: "mb-0" }, [
                                  _vm._v(
                                    " " + _vm._s(_vm.userData.fullName) + " "
                                  )
                                ])
                              ]),
                              _vm.$can("update", "users")
                                ? _c(
                                    "div",
                                    { staticClass: "d-flex flex-wrap" },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "mr-1 mb-1",
                                          attrs: {
                                            to: {
                                              name: "apps-users-edit",
                                              params: {
                                                username: _vm.userData.username
                                              }
                                            },
                                            variant: "primary"
                                          }
                                        },
                                        [_vm._v(" Edit ")]
                                      ),
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "mb-1",
                                          attrs: { variant: "primary" },
                                          on: {
                                            click: _vm.handleResetPasswordClick
                                          }
                                        },
                                        [_vm._v(" Reset Password ")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _c("b-col", { attrs: { cols: "12", xl: "4", lg: "4" } }, [
                    _c("table", { staticClass: "mt-2 mt-xl-0 w-100" }, [
                      _c("tr", [
                        _c(
                          "th",
                          {
                            staticClass: "pb-50",
                            staticStyle: { "min-width": "120px" }
                          },
                          [
                            _c("feather-icon", {
                              staticClass: "mr-75",
                              attrs: { icon: "UserIcon" }
                            }),
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v("Username")
                            ])
                          ],
                          1
                        ),
                        _c("td", { staticClass: "pb-50" }, [
                          _vm._v(" " + _vm._s(_vm.userData.username) + " ")
                        ])
                      ]),
                      _c("tr", [
                        _c(
                          "th",
                          { staticClass: "pb-50" },
                          [
                            _vm.userData.status
                              ? _c("feather-icon", {
                                  staticClass: "mr-75",
                                  attrs: { icon: "UserCheckIcon" }
                                })
                              : _c("feather-icon", {
                                  staticClass: "mr-75",
                                  attrs: { icon: "UserXIcon" }
                                }),
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v("Status")
                            ])
                          ],
                          1
                        ),
                        _c("td", { staticClass: "pb-50 text-capitalize" }, [
                          _vm._v(" " + _vm._s(_vm.userData.status) + " ")
                        ])
                      ]),
                      _c("tr", [
                        _c(
                          "th",
                          { staticClass: "pb-50" },
                          [
                            _c("feather-icon", {
                              staticClass: "mr-75",
                              attrs: { icon: "ArrowUpRightIcon" }
                            }),
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v("Onboarding")
                            ])
                          ],
                          1
                        ),
                        _c("td", { staticClass: "pb-50 text-capitalize" }, [
                          _vm._v(" " + _vm._s(_vm.userData.is_onboarding) + " ")
                        ])
                      ]),
                      _c("tr", [
                        _c(
                          "th",
                          { staticClass: "pb-50" },
                          [
                            _c("feather-icon", {
                              staticClass: "mr-75",
                              attrs: { icon: "UsersIcon" }
                            }),
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v("Client")
                            ])
                          ],
                          1
                        ),
                        _c("td", { staticClass: "pb-50 text-capitalize" }, [
                          _vm._v(
                            " " + _vm._s(_vm.userData.client_display_name) + " "
                          )
                        ])
                      ]),
                      _c("tr", [
                        _c(
                          "th",
                          { staticClass: "pb-50" },
                          [
                            _c("feather-icon", {
                              staticClass: "mr-75",
                              attrs: { icon: "StarIcon" }
                            }),
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v("Role")
                            ])
                          ],
                          1
                        ),
                        _c("td", { staticClass: "pb-50 text-capitalize" }, [
                          _vm._v(" " + _vm._s(_vm.userData.role) + " ")
                        ])
                      ]),
                      _c("tr", [
                        _c(
                          "th",
                          { staticClass: "pb-50" },
                          [
                            _c("feather-icon", {
                              staticClass: "mr-75",
                              attrs: { icon: "GlobeIcon" }
                            }),
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v("Region")
                            ])
                          ],
                          1
                        ),
                        _c("td", { staticClass: "pb-50 text-capitalize" }, [
                          _vm._v(" " + _vm._s(_vm.userData.region_loc) + " ")
                        ])
                      ]),
                      _c("tr", [
                        _c(
                          "th",
                          { staticClass: "pb-50" },
                          [
                            _c("feather-icon", {
                              staticClass: "mr-75",
                              attrs: { icon: "MailIcon" }
                            }),
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v("Email")
                            ])
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c(
                              "b-link",
                              {
                                attrs: { href: "mailto:" + _vm.userData.email }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatPhoneNumber(_vm.userData.email)
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]),
                      _c("tr", [
                        _c(
                          "th",
                          { staticClass: "pb-50" },
                          [
                            _c("feather-icon", {
                              staticClass: "mr-75",
                              attrs: { icon: "PhoneIcon" }
                            }),
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v("Phone")
                            ])
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c(
                              "b-link",
                              { attrs: { href: "tel:" + _vm.userData.phone } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatPhoneNumber(_vm.userData.phone)
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]),
                      _c("tr", [
                        _c(
                          "th",
                          { staticClass: "pb-50" },
                          [
                            _c("feather-icon", {
                              staticClass: "mr-75",
                              attrs: { icon: "UserIcon" }
                            }),
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v("Account")
                            ])
                          ],
                          1
                        ),
                        _c("td", [
                          _vm._v(
                            " " + _vm._s(_vm.userData.cognito_status) + " "
                          )
                        ])
                      ])
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-modal",
        {
          ref: "rpmodal",
          attrs: {
            id: "rpmodal",
            "ok-title": "Reset Password",
            "cancel-variant": "outline-secondary",
            "cancel-title": "Cancel",
            centered: "",
            size: "lg",
            title: "New Content Available"
          },
          on: {
            close: function($event) {
              _vm.showTPModal = false
            }
          }
        },
        [
          _vm._v(
            " Are you sure you wish to reset " +
              _vm._s(_vm.userData.fullName) +
              "'s password? "
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }